<template>
  <div class="processing-services-box">
    <!-- 选择搜索框 -->
    <SearchTechnology :goods-type="formInline.goodsTopCategoryId" @changeGoodsCondition="changeGoodsCondition" @changeSearch="changeSearch" />
    <!-- 排序 -->
    <div class="sortPageBox">
      <span :class="sortActive === 1 ? 'active' : ''" @click="changeActive(1)">综合排序</span>
      <span :class="sortActive === 2 ? 'active' : ''" @click="changeActive(2)">厂房面积
        <p class="icon">
          <i :class="sortStock === 0 ? 'el-icon-caret-top active' : 'el-icon-caret-top'" />
          <i :class="sortStock === 1 ? 'el-icon-caret-bottom active' : 'el-icon-caret-bottom'" />
        </p>
      </span>
      <span>
        <el-cascader
          ref="areaRef"
          v-model="addressArr"
          class="address-cascader"
          :props="{ label: 'name', value: 'code', children: 'children', checkStrictly: true }"
          :options="allCityOption"
          clearable
          placeholder="所有地区"
          @change="getReceivingArea"
        />
      </span>
    </div>
    <!-- 数据列表 -->
    <div class="list-box">
      <div v-for="item in listData" :key="item.id" class="item-box">
        <div class="item-top">
          <img v-if="item.shopLogo" class="top-logo" :src="$fileUrl + item.shopLogo" alt="">
          <img v-else class="top-logo" src="@/assets/storeManagement/image/store_logo.png" alt="">
          <div class="top-right">
            <div class="company">
              <p class="name">
                {{ item.cmpName }}
              </p>
              <p class="view" @click="viewHomePage(item)">
                进店看看
              </p>
            </div>
            <div class="locate-box">
              <i class="locate-icon" />{{ item.factoryProvinceName + item.factoryCityName }}
            </div>
          </div>
        </div>
        <div class="item-img-box">
          <img v-for="cl in item.tradeFactoryProductInfoList.slice(0, 3)" :key="cl.id" :src="$fileUrl + cl.productImgUrlOne" alt="">
        </div>
      </div>
    </div>
    <Pagination v-if="listData.length>0" :get-data-list="getPageData" :params="formInline" :total="total" />
    <NoData v-else class="noData" :type="3" />
  </div>
</template>

<script>
import SearchTechnology from '@/components/SearchTechnology.vue'
import Pagination from '@/components/Pagination.vue'
import NoData from '@/components/NoData.vue'
import { listPageFactoryBusiness } from '@/http/processingServices'
import { getSelectAreaTree } from '@/http/common'
export default {
  components: { Pagination, SearchTechnology, NoData },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        keyword: ''
      },
      listData: [],
      total: 0,
      sortActive: 1,
      sortStock: 0,
      addressArr: [],
      allCityOption: []
    }
  },
  mounted() {
    const filterLevel = (list, level = 1) => list.map(v => ({ ...v, children: level <= 1 ? null : filterLevel(v.children, level - 1) }))
    getSelectAreaTree('00', res => {
      this.allCityOption = filterLevel(res.data, 2)
    })
    this.getPageData()
  },
  methods: {
    getPageData() {
      listPageFactoryBusiness(this.formInline, res => {
        this.listData = res.data.records
        this.total = res.data.total
      })
    },
    changeGoodsCondition(val) {
      const technologyArr = val.map((item) => {
        if (item.type === 'technology') {
          return item.dictId
        }
      })
      const materialArr = val.map((item) => {
        if (item.type === 'material') {
          return item.dictId
        }
      })
      this.formInline.technology = technologyArr.filter(item => item !== undefined)
      this.formInline.material = materialArr.filter(item => item !== undefined)
      this.getPageData()
    },
    changeSearch(val) {
      this.formInline.keyword = val
      this.getPageData()
    },
    changeActive(index) {
      this.sortActive = index
      this.formInline.orderRuleData = {}
      switch (index) {
        case 2:
          this.formInline.orderRuleData.orderColumnName = 'factoryArea'
          if (this.sortStock === 2) {
            this.sortStock = 0
            return
          }
          this.sortStock += 1
          console.log(this.sortStock)
          if (this.sortStock === 1) this.formInline.orderRuleData.orderRule = 'asc'
          if (this.sortStock === 2) this.formInline.orderRuleData.orderRule = 'desc'

          break
        default:
          this.sortStock = 0
          this.formInline.pageSize = 10
          this.formInline.pageNum = 1
          delete this.formInline.orderRuleData
          break
      }
      this.getPageData()
    },
    // 获取地区
    getReceivingArea(val) {
      if (!val) {
        this.formInline.factoryProvince = ''
        this.formInline.factoryCity = ''
      } else {
        this.formInline.factoryProvince = val[0]
        this.formInline.factoryCity = val[1]
      }
      this.getPageData()
    },
    // 进店看看
    viewHomePage(item) {
      this.$router.push({
        path: `/processingServices/processServicesHome?id=${item.id}&shopId=${item.shopId}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.processing-services-box {
  width: $centerPlateWidth;
  margin: 54px auto 0;

  .sortPageBox {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 42px;
    background-color: rgba(0,0,0,0.03);
    padding-left: 18px;

    span {
      width: auto;
      height: 40px;
      margin-right: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: $mainFont;
      cursor: pointer;

      .icon {
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        i {
          font-size: 10px;
          color: #C1C1C1;
          transform: translateY(-2px);
        }

        i:first-of-type {
          transform: translateY(2px);
        }

        .active {
          color: #333;
        }
      }
    }

    span.active {
      border-bottom: 1px solid $themeColors;
      color: $themeColors;
    }
  }

  .list-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .item-box {
    width: 592px;
    background-color: #fff;
    padding: 16px 24px 24px;
    box-sizing: border-box;

    .item-top {
      display: flex;

      .top-logo {
        width: 72px;
        height: 72px;
        margin-right: 16px;
      }

      .top-right {
        flex: 1;
        .company {
          display: flex;
          justify-content: space-between;

          .name {
            font-size: 20px;
            font-weight: bolder;
          }

          .view {
            width: 99px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border: 1px solid #e1e1e1ff;
            color: #333;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }

      .locate-box {
        color: #666666ff;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-top: 8px;

        .locate-icon {
          width: 20px;
          height: 20px;
          display: inline-block;
          background: url('../../assets/icon/locate.png') no-repeat top center;
          background-size: contain;
          flex-shrink: 0;
        }

        .elp-1 {
          flex: 1;
        }
      }
    }

    .item-img-box {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      img {
        width: 170px;
        height: 170px;
      }
    }
  }

}
/deep/.address-cascader {
  width: 160px;
    .el-input__inner {
       background-color: rgba(0, 0, 0, 0) !important;
       border: none;
       &::placeholder {
        color: #333;
      }
    }
  }
</style>
