<template>
  <div class="searchPageBox">
    <!-- 已选择好的搜索条件啊 -->
    <div class="selectedBox">
      您已选择<i class="el-icon-arrow-right" />
      <div class="name-box">
        <span v-for="(item, index) in currentCategoryName" :key="item.dictName"><span v-if="item.dictName" class="item">{{ item.dictName }}<i class="el-icon-close" @click="removeCurrentCategory(index)" /></span></span>
      </div>
      <span v-if="currentCategoryName.length > 0" class="clear-search" @click="removeCurrentCategory('all')">清空筛选</span>
    </div>
    <table class="searchTable">
      <!-- 主营工艺 -->
      <tr>
        <td class="front">
          <p>主营工艺</p>
        </td>
        <td>
          <div class="contentBox">
            <span v-for="item in FACTORYTECHNOLOGYLIST" :key="item.dictName" :class="activeIndex.indexOf(item.dictName) !== -1 ? 'active productName elp-1' : 'productName elp-1'" :title="item.dictName" @click="selectCategory(item)">{{ item.dictName }}</span>
          </div>
        </td>
        <!-- <td class="more">
          <p v-if="item.children.length>13" :class="showMore.indexOf(item.code) !== -1 ? 'active' : ''" @click="clickShowMore(item)">
            {{ showMore.indexOf(item.code) !== -1 ? '收起' : '展开' }}<i class="el-icon-arrow-down" />
          </p>
        </td> -->
      </tr>
      <!-- 加工原料 -->
      <tr>
        <td class="front">
          <p>加工原料</p>
        </td>
        <td>
          <div class="contentBox">
            <span v-for="item in FACTORYMATERIALLIst" :key="item.dictName" :class="activeIndex.indexOf(item.dictName) !== -1 ? 'active productName elp-1' : 'productName elp-1'" :title="item.dictName" @click="selectCategory(item)">{{ item.dictName }}</span>
          </div>
        </td>
        <!-- <td class="more">
          <p v-if="$store.getters.getDictionaryItem('FACTORY_MATERIAL').length>13" :class="showMore.indexOf(item.dictId) !== -1 ? 'active' : ''" @click="clickShowMore(item)">
            {{ showMore.indexOf(item.code) !== -1 ? '收起' : '展开' }}<i class="el-icon-arrow-down" />
          </p>
        </td> -->
      </tr>
      <tr class="select-tr">
        <td class="front">
          <p>其他搜索</p>
        </td>
        <td>
          <el-input v-model="searchValue" placeholder="请输入工艺、产品、原料等" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" class="search-icon-btn" @click="changeSearch" />
          </el-input>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      // 高亮
      activeIndex: '',
      // 展开
      showMore: [],
      // 当前已选择
      currentCategoryName: [],
      searchValue: '',
      FACTORYTECHNOLOGYLIST: [],
      FACTORYMATERIALLIst: []
    }
  },
  mounted() {
    this.FACTORYTECHNOLOGYLIST = this.$store.getters.getDictionaryItem('FACTORY_TECHNOLOGY')
    this.FACTORYTECHNOLOGYLIST.map((item) => {
      item.type = 'technology'
      return item
    })
    this.FACTORYMATERIALLIst = this.$store.getters.getDictionaryItem('FACTORY_MATERIAL')
    this.FACTORYMATERIALLIst.map((item) => {
      item.type = 'material'
      return item
    })
  },
  methods: {
    // 选择商品类别
    selectCategory(item) {
      // //  如果已经选过不用重复选择
      const findIndex = this.currentCategoryName.findIndex((val) => val.dictName === item.dictName)
      if (findIndex !== -1) return
      // // 第一次选择
      // if (this.currentCategoryName.length === 0) {
      //   this.currentCategoryName.push(item)
      // } else {
      //   const indexItem = this.currentCategoryName.findIndex((val) => val.type === item.type)
      //   // 判断是否有同类型
      //   if (indexItem !== -1) {
      //     // 是 就替换
      //     this.currentCategoryName.splice(indexItem, 1, item)
      //   } else {
      //     // 否 就添加
      //     this.currentCategoryName.push(item)
      //   }
      // }
      this.currentCategoryName.push(item)
      this.activeIndex = item.dictName
      this.$emit('changeGoodsCondition', this.currentCategoryName)
    },
    // 移除当前已选
    removeCurrentCategory(index) {
      if (index === 'all') {
        this.currentCategoryName = []
      }
      this.currentCategoryName.splice(index, 1)
      this.$emit('changeGoodsCondition', this.currentCategoryName)
    },
    // 展开事件
    clickShowMore(item) {
      const findIndex = this.showMore.findIndex((val) => val === item.code)
      if (findIndex !== -1) return this.showMore.splice(findIndex, 1)
      this.showMore.push(item.code)
    },
    // 搜索
    changeSearch() {
      this.$emit('changeSearch', this.searchValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.searchPageBox {
  width: 100%;

  .searchTable {
    display: block;
    width: 100%;
    padding: 6px 0 6px 20px;
    background-color: #fff;

    tr {
      height: 44px;
      overflow: hidden;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0,0,0,0.04);
      }

      .more {
        vertical-align: top;
        width: 65px;
        line-height: 43px;
        color: $fontColor;
        font-size: 12px;

        p {
          cursor: pointer;
          i{
            transition: all .5s;
            font-size: 14px;
            margin-left: 8px;
          }
        }
        .active{
          color: $themeColors;
          i{
            color: $themeColors;
            transform: rotate(180deg);
          }
        }
      }

      .contentBox {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: $mainFont;
        font-size: 12px;
        margin-left: 8px;
        width: calc(100% - 8px);
        .productName{
          width: 60px;
          height: 43px;
          line-height: 43px;
        }
        .el-select{
          width: 180px;
          /deep/.el-input__inner{
            border: 0;
            outline: 0;
          }
        }

        span {
          margin-right: 20px;
          line-height: 36px;
          cursor: pointer;
        }
        .active{
          color: $themeColors;
        }
      }

      .front {
        vertical-align: top;
        font-size: 12px;
        color: $fontColor1;
        width: 56px;
        line-height: 43px;
        padding-right: 24px;
      }

    }

  }
}
.selectedBox {
  display: flex;
  align-items: center;
  // height: 22px;
  font-size: 14px;
  color: #999999;
  margin-bottom: 16px;

  .name-box {
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    span {
      margin-bottom: 2px;
    }
  }

    span {
      color: #666;
      flex-shrink: 0;
    }

  .item {
    display: flex;
    align-items: center;
    padding: 0 4px 0 8px;
    border: 1px solid $borderColor4;
    margin-left: 12px;
    height: 20px;

    i {
      font-size: 12px;
      color: #C1C1C1;
      margin-left: 4px;
      cursor: pointer;
    }
  }

  .item:first-of-type {
    margin-left: 4px;
  }

  .clear-search {
    cursor: pointer;
    margin-left: 8px;
  }
}
.select-tr {
  display: inline-block;
  padding: 10px 0;
  .front {
    vertical-align: middle!important;
  }
  .input-with-select {
    width: 452px;
    height: 40px ;
  }

  .search-icon-btn {
    background-color: #fff;
  }
}

</style>
